<template>
	<el-tabs v-model="activeName2" style="background-color: #fff; padding: 10px 30px" @tab-click="changeTab" class="secondTabs" v-loading="loading">
		<el-tab-pane label="审批流" name="first" v-if="permissionControl('ApprovalProcess')">
			<div style="background-color: #fff">
				<el-tabs class="infoTab apprpvalsecondTab" @input="changeQueryType" :value="activeName" style="margin-top: 10px">
					<el-tab-pane :label="`我发起的(${queryTypeCount[1] || 0})`" name="1" v-if="permissionControl('ApStart')"></el-tab-pane>
					<el-tab-pane :label="`待处理(${queryTypeCount[2] || 0})`" name="2" v-if="permissionControl('ApPending')"></el-tab-pane>
					<el-tab-pane :label="`已处理(${queryTypeCount[3] || 0})`" name="3" v-if="permissionControl('ApProcessed')"></el-tab-pane>
				</el-tabs>
				<!-- <el-col :span="5">
            <div class="input-field">
              <span class="field-label">姓名</span>
              <el-input class="field"
                        @keydown.enter.native="filter"
                        v-model="apiParams.approvalUserName"></el-input>
            </div>
          </el-col>-->
				<!-- <el-col :span="5">
          <div class="input-field">
            <span>部门</span>
            <treeselect :props="depProps"
                        :options="deps"
                        :value="depsId"
                        :clearable="isClearable"
                        :accordion="isAccordion"
                        :level="depsId"
                        @getValue="getValue($event)"
                        style="width:100%" />
          </div>
          </el-col>-->
				<!-- <el-col :span="5">
            <div class="input-field">
              <span class="field-label">审批状态</span>
              <el-select class="field"
                        @change="filter"
                        v-model="apiParams.approvalStatus"
                        clearable
                        placeholder="请选择">
                <el-option v-for="(item,index) in statusOptions"
                          :key="index"
                          :label="item.dictName"
                          :value="item.dictId">
                </el-option>
              </el-select>
            </div>
          </el-col>-->
				<el-form inline :model="apiParams" class="demo-form-inline">
					<el-form-item label="审批类型">
						<el-select class="field" @change="filter" v-model="apiParams.approvalType" clearable placeholder="请选择" size="small">
							<el-option v-for="(item, index) in typeOptions" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="发起时间">
						<el-date-picker
							size="small"
							class="field"
							@change="filter"
							v-model="apiParams.createStartTime"
							value-format="yyyy-MM-dd [00:00:00]"
							format="yyyy-MM-dd"
							type="date"
						></el-date-picker>
						<span style="margin: 0 5px">-</span>
						<el-date-picker
							size="small"
							class="field"
							@change="filter"
							v-model="apiParams.createEndTime"
							value-format="yyyy-MM-dd [23:59:59]"
							format="yyyy-MM-dd"
							type="date"
						></el-date-picker>
					</el-form-item>
					<el-form-item style="float: right; margin-right: 0" v-if="permissionControlBtns(pageName, 'Apply')">
						<el-button @click="openDialog(Application)" size="small" v-if="$route.path === '/staff/approval'" type="primary">
							<i class="el-icon-s-order"></i>
							<span>申请</span>
						</el-button>
					</el-form-item>
				</el-form>
				<div class="main-table" style="padding: 0px">
					<el-table :data="tableList" stripe border @cell-click="clickTableCell">
						<el-table-column label="序号" align="center" width="120px">
							<template v-slot="{ $index }">{{ $index + 1 }}</template>
						</el-table-column>
						<el-table-column label="审批类型" align="center" prop="approvalTypeName"></el-table-column>
						<!-- <el-table-column label="审批名称" align="center" prop="approvalName"></el-table-column> -->
						<el-table-column label="审批内容" align="center" prop="approvalContent">
							<template slot-scope="scope"
								><div style="white-space: pre-line">{{ scope.row.approvalContent }}</div></template
							>
						</el-table-column>
						<el-table-column label="当前审批人" align="center" prop="principalName"></el-table-column>
						<el-table-column label="发起时间" align="center" prop="createTime"></el-table-column>
						<el-table-column label="审批状态" align="center" prop="approvalStatusName">
							<template slot-scope="{ row }">
								<div :style="`color:${retrunColor(row.approvalStatus)}`" v-if="activeName != '3'">
									{{ row.approvalStatusName }}
								</div>
								<div :style="`color:${retrunColor(row.ownApprovalStatus)}`" v-else>
									{{ row.ownApprovalStatusName }}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template v-slot="{ row }">
								<el-popover placement="left" width="96" trigger="click">
									<el-button type="text" slot="reference">
										<img src="../../assets/button/操作图标@3x.png" width="16px" />
									</el-button>
									<ul class="drop-menu">
										<li
											class="menu-item"
											v-for="(item, i) in makeComands(row, apiParams.queryType, $store.state.app.staffId)"
											@click="operate(row, item)"
											:key="i"
										>
											{{ item.label }}
										</li>
									</ul>
								</el-popover>
								<!--<section placement="left" width="96" trigger="click" v-else>
                    <el-button
                      type="text"
                      class="menu-item"
                      v-for="(item, i) in makeComands(row, apiParams.queryType,$store.state.app.staffId)"
                      @click="operate(row,item)"
                      :key="i"
                    >{{item.label}}</el-button>
                </section>!-->
							</template>
						</el-table-column>
					</el-table>
				</div>
				<el-dialog :visible.sync="dialogVisible" width="30%" center :title="dialogAction.title">
					<template v-if="dialogAction === Pass">
						<h3 style="text-align: center; margin: -10px 0 25px; font-size: 15px; font-weight: bold">
							你确定要通过“{{ currentRow.createStaffName }}”的{{ currentRow.approvalTypeName }}申请
						</h3>
					</template>
					<el-form label-width="95px">
						<el-form-item :label="makeOperationTip(currentRow, dialogAction)">
							<el-input
								type="textarea"
								:maxlength="100"
								style="width: 90%"
								v-model="approvalParams.approvalDescription"
								:rows="4"
							></el-input>
						</el-form-item>
						<el-form-item label="类型" v-if="showType == 'pass'">
							<el-radio-group v-model="radioType">
								<el-radio label="1">本人</el-radio>
								<el-radio label="2">转交他人</el-radio>
								<el-radio label="3">插入上级</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="转交人" v-if="showType == 'pass' && radioType != 1">
							<el-select v-model="approvalParams.staffId" filterable :filter-method="_getStaff" placeholder="请选择" size="small">
								<el-option v-for="item in userArr" :key="item.staffId" :value="item.staffId" :label="item.staffName"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="附件" v-if="(showType == 'pass' && radioType == 1) || showType == 'reject'">
							<el-upload
								ref="upload"
								class="upload-demo"
								action="#"
								element-loading-text="正在上传......"
								show-file-list
								multiple
								:limit="5"
								v-loading="contrachLoading"
								:file-list="fileList"
								:on-exceed="handleExceed"
								:on-change="handleChange"
								:on-remove="handleRemove"
								:auto-upload="false"
								accept=".JPG, .JPEG, .DOC, .DOCX, .XLS, .XLSX, .PDF, .ZIP, .RAR"
							>
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip" style="line-height: 20px; width: 90%">
									文件格式为JPG/JPEG/DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在5个之内
								</div>
							</el-upload>
						</el-form-item>
					</el-form>
					<div slot="footer">
						<el-button @click="cancelDialog">取消</el-button>
						<el-button type="primary" :loading="submitting" @click="submitApproval">确认</el-button>
					</div>
				</el-dialog>
				<apply-approval :mode="mode" :id="applyId" :visible.sync="applyVisible" @success="updateApplySuccess"></apply-approval>
				<el-pagination
					background
					slot="footer"
					:current-page.sync="currentPage"
					:page-size="apiParams.pageSize"
					@current-change="handleCurrentChange"
					layout="prev, pager, next"
					:page-count="totalPage"
				></el-pagination>
			</div>
			<app-layout size="none">
				<template slot="header"></template>
			</app-layout>
		</el-tab-pane>
		<el-tab-pane label="招聘调配" name="second" v-if="permissionControl('RecruitmentDeployment')">
			<div style="background-color: #fff; padding: 0px 0px 20px; margin-top: -10px">
				<div style="position: relative">
					<el-tabs class="infoTab secondsTab" :value="table2Params.queryType" @tab-click="table2TabClick" style="margin-top: 10px">
						<el-tab-pane :label="`待处理(${queryTypeCount[1] || 0})`" name="0" v-if="permissionControl('RePending')"></el-tab-pane>
						<el-tab-pane :label="`已处理(${queryTypeCount[2] || 0})`" name="1" v-if="permissionControl('ReProcessed')"></el-tab-pane>
					</el-tabs>
					<!--<el-row style="padding:0 20px;margin:10px">!-->
					<!-- <el-col :span="5">
              <div class="input-field">
                <span class="field-label">创建时间</span>
                <el-date-picker
                  class="field"
                  @change="filter"
                  :value="[table2Params.startTime||'', table2Params.endTime||'']"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="daterange"
                  @input="(val)=>{[this.table2Params.startTime, this.table2Params.endTime]=val?val:['',''];}"
                  style="margin-right:5px;"
                ></el-date-picker>
              </div>
            </el-col>
          </el-row> -->
					<el-form inline :model="table2Params" class="demo-form-inline">
						<el-form-item label="创建时间">
							<el-date-picker
								size="small"
								class="field"
								@change="getTable2Data"
								v-model="table2Params.startTime"
								value-format="yyyy-MM-dd [00:00:00]"
								format="yyyy-MM-dd"
								type="date"
							></el-date-picker>
							<span style="margin: 0 5px">-</span>
							<el-date-picker
								size="small"
								class="field"
								@change="getTable2Data"
								v-model="table2Params.endTime"
								value-format="yyyy-MM-dd [23:59:59]"
								format="yyyy-MM-dd"
								type="date"
							></el-date-picker>
						</el-form-item>
					</el-form>
					<!--<el-button
            size="small"
            type="primary"
            style="position: absolute;right: 10px;z-index: 10;top: 0;"
            @click="getTable2List(1)"
          >查询</el-button>!-->
				</div>
				<div class="main-table" style="margin-bottom: 20px">
					<el-table :data="table2List" stripe border v-if="!pageLoading">
						<el-table-column label="序号" align="center">
							<template v-slot="{ $index }">{{ $index + 1 }}</template>
						</el-table-column>
						<el-table-column
							v-for="(col, i) in table2Params.queryType == 0 ? tableCloumns : tableCloumns2"
							:prop="col.prop"
							:key="i"
							align="center"
							:show-overflow-tooltip="col.prop == 'applyReason'"
							:label="col.label"
						>
							<template v-slot="{ row }" v-if="col.prop == 'operate'">
								<el-button type="text" @click="table2Tab(row)">审批</el-button>
							</template>
							<template v-slot="{ row }" v-else-if="col.prop == 'createTime'">
								{{ row.createTime && row.createTime.slice(0, 10) }}
							</template>
						</el-table-column>
					</el-table>
				</div>
				<el-pagination
					background
					slot="footer"
					:current-page="table2Params.pageNo"
					:page-size="table2Params.pageSize"
					@current-change="handleCurrentChange2"
					layout="prev, pager, next"
					:page-count="table2Params.totalPage"
				></el-pagination>
				<el-dialog :title="`${currentRow2.name}再入职申请`" :visible.sync="dialogVisible2" center class="loser" width="45%">
					<el-form ref="form2" :rules="form2.status == 1 ? rules2s : rules2" :model="form2" label-width="80px">
						<el-form-item prop="status" label="状态" style="margin-bottom: 15px">
							<el-radio-group v-model="form2.status">
								<el-radio :label="1">通过</el-radio>
								<el-radio :label="2">不通过</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item prop="reason" label="原因" style="margin-bottom: 15px">
							<el-input
								type="textarea"
								maxlength="100"
								minlength="1"
								v-model="form2.reason"
								placeholder="1-100字符"
								:autosize="{ minRows: 4, maxRows: 4 }"
							/>
						</el-form-item>
						<el-row style="text-align: center; margin: 25px 0 10px">
							<el-button @click="dialogVisible2 = false">取 消</el-button>
							<el-button type="primary" @click="handleApproval" :loading="handleApprovalLoading">确 定</el-button>
						</el-row>
					</el-form>
				</el-dialog>
			</div>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
import { getApprovalList, operateApproval, recruitAnewEntryApprovalList, recruitAnewEntryApproval, approvalChange } from '@/api/staffSystem';
import PageMixin from '../../mixins/pageQuery';
import ApplyApproval from './components/ApprovalForm';
import { getStaffList } from '@/api/system';
import { EXISTAPPROVALPENDING } from '../../store/types';
import { Reject, Pass, Print, Cancel, Details, makeComands, Mode, Edit, makeOperationTip } from './const';
const Application = '申请审核';

export default {
	mixins: [PageMixin],
	name: 'approval',
	components: {
		ApplyApproval
	},
	data() {
		return {
			Mode,
			Reject,
			Application,
			Pass,
			Print,
			Cancel,
			Details,
			activeName: '1',
			activeName2: '',
			mode: '',
			applyId: 0,
			typeOptions: [],
			applyVisible: false,
			statusOptions: [],
			apiParams: {
				createStartTime: '',
				createEndTime: '',
				pageNo: 1,
				queryType: '1',
				approvalUserName: '',
				pageSize: 20,
				approvalStatus: ''
			},
			queryTypeCount: {},
			currentRow: {},
			dialogVisible: false,
			dialogAction: '',
			tableList: [],
			properties: [],
			submitting: false,
			approvalTypes: [],
			approvalParams: {
				applyId: '',
				approvalStatus: '',
				approvalDescription: ''
			},
			table2Params: {
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20,
				totalPage: 0,
				queryType: 0
			},
			form2: {
				approvalId: '',
				status: 1,
				reason: ''
			},
			rules2: {
				status: [{ required: true, message: '请选择状态', trigger: 'change' }],
				reason: [{ required: true, message: '请输入原因', trigger: 'blur' }]
			},
			rules2s: {
				status: [{ required: true, message: '请选择状态', trigger: 'change' }]
			},
			tableCloumns: [
				{ label: '姓名', prop: 'name' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '申请类型', prop: 'applyType' },
				{ label: '申请原因', prop: 'applyReason' },
				{ label: '申请人', prop: 'nickName' },
				{ label: '创建时间', prop: 'createTime' },
				{ label: '操作', prop: 'operate' }
			],
			tableCloumns2: [
				{ label: '姓名', prop: 'name' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '申请类型', prop: 'applyType' },
				{ label: '申请原因', prop: 'applyReason' },
				{ label: '申请人', prop: 'nickName' },
				{ label: '状态', prop: 'approvalStatusName' },
				{ label: '原因', prop: 'reason' },
				{ label: '创建时间', prop: 'createTime' }
			],
			currentRow2: {},
			dialogVisible2: false,
			radioType: '1', // 审批通过类型
			fileList: [],
			uploadVal: [],
			contrachLoading: false,
			userArr: [],
			showType: '',
			table2List: [],
			handleApprovalLoading: false,
			loading: false,
			pageLoading: false,
			currentPage: 1
		};
	},
	computed: {
		existApprovalPending: {
			get() {
				return this.$store.state.approval.existApprovalPending;
			},
			set(val) {
				this.$store.commit(EXISTAPPROVALPENDING, val);
			}
		},
		pageName() {
			let name = '';

			switch (this.activeName2) {
				case 'first':
					// eslint-disable-next-line no-nested-ternary
					name = this.activeName === '1' ? 'ApStart' : this.activeName === '2' ? 'ApPending' : this.activeName === '3' ? 'ApProcessed' : '';
					break;
				case 'second':
					// eslint-disable-next-line no-nested-ternary
					name = this.table2Params.queryType == 0 ? 'RePending' : this.table2Params.queryType == 1 ? 'ReProcessed' : '';
					break;

				default:
					break;
			}
			return name;
		},
		permissionList() {
			return this.$store.state.app.menusPermission;
		}
	},
	mounted() {
		this.queryToApiParams();
		this.activeName = this.apiParams.queryType;
		this.getPageData();
		this.getQueryTypeCount();
		this.queryBadge();
		this.$nextTick(() => {
			const dict = this.$store.state.app.dict || [];

			this.typeOptions = dict.filter((item) => item.groupId === 'subType');
			this.statusOptions = dict.filter((item) => item.groupId === 'approvalStatus');
		});
		this.getTable2List();
		this._getStaff();
		// 第一层tab
		this.setActiveTab(this.$route.name, [
			{ name: 'first', key: 'ApprovalProcess' },
			{ name: 'second', key: 'RecruitmentDeployment' }
		]);
		// 第二层 tab
		const list =
			this.activeName2 === 'first'
				? [
						{ name: '1', key: 'ApStart' },
						{ name: '2', key: 'ApPending' },
						{ name: '3', key: 'ApProcessed' }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: [
						{ name: '0', key: 'ApProcessed' },
						{ name: '1', key: 'ReProcessed' }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ];

		this.setActiveTab(this.activeName2 === 'first' ? 'ApprovalProcess' : 'RecruitmentDeployment', list);
	},
	methods: {
		makeOperationTip,
		makeComands(row, queryType, staffId) {
			let arr = makeComands(row, queryType, staffId);

			if (arr?.length && this.permissionControlBtns(this.pageName)) {
				// eslint-disable-next-line no-nested-ternary
				arr = arr.filter((v) => this.permissionControlBtns(this.pageName, v.label === '详情' ? 'Detail' : v.label === '编辑' ? 'Edit' : ''));
			}
			return arr;
		},
		retrunColor(status) {
			const stat = Number(status);

			// eslint-disable-next-line default-case
			switch (stat) {
				case 0:
					return '#409EFF';
				case 1:
					return '#64C940';
				case 2:
					return '#FF604D';
				case 3:
					return '#7f7f7f';
			}
		},
		updateApplySuccess() {
			this.getPageData();
			this.getQueryTypeCount();
		},
		changeTab(tab) {
			console.log(tab.name, 'tab');
			// eslint-disable-next-line default-case
			switch (tab.name) {
				case 'first': {
					this.queryToApiParams();
					this.activeName = this.apiParams.queryType;
					this.getPageData();
					this.getQueryTypeCount();
					this.queryBadge();
					this.$nextTick(() => {
						const dict = this.$store.state.app.dict || [];

						this.typeOptions = dict.filter((item) => item.groupId === 'subType');
						this.statusOptions = dict.filter((item) => item.groupId === 'approvalStatus');
					});
					break;
				}
				case 'second': {
					this.getQueryTypeCount1();
					this.getTable2List(1);
					break;
				}
			}
		},
		getQueryTypeCount1() {
			Promise.all([
				this.queryrecruit({ pageNo: 1, pageSize: 1, queryType: 0 }),
				this.queryrecruit({ pageNo: 1, pageSize: 1, queryType: 1 })
			]).then((resList) => {
				this.queryTypeCount = {
					1: resList[0] && resList[0].totalNum,
					2: resList[1] && resList[1].totalNum
				};
			});
		},
		getQueryTypeCount() {
			Promise.all([
				this.queryApprovalList({ pageNo: 1, pageSize: 1, queryType: 1 }),
				this.queryApprovalList({ pageNo: 1, pageSize: 1, queryType: 2 }),
				this.queryApprovalList({ pageNo: 1, pageSize: 1, queryType: 3 })
			]).then((resList) => {
				this.queryTypeCount = {
					1: resList[0] && resList[0].totalNum,
					2: resList[1] && resList[1].totalNum,
					3: resList[2] && resList[2].totalNum
				};
			});
		},
		cancelDialog() {
			this.approvalParams.approvalDescription = '';
			this.dialogVisible = false;
		},
		changeQueryType(name) {
			if (name !== this.activeName) {
				this.apiParams.queryType = this.activeName = name;
				this.filter();
			}
		},
		operate(row, command) {
			this.showType = '';
			if (command === Details) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.id,
						staffId: row.staffId,
						queryType: this.apiParams.queryType,
						approvalTypeName: row.approvalTypeName,
						subType: row.subType
					}
				});
			} else if (command === Print) {
				this.$router.push({
					path: '/staff/print',
					query: { id: row.id, queryType: this.apiParams.queryType }
				});
			} else if (command === Edit) {
				this.applyId = row.id;
				this.showApplyApproval(Mode.Edit, row);
			} else if (
				(row.subType == '3' || row.subType == '6') &&
				this.$store.state.app.range.staffIds &&
				this.$store.state.app.range.staffIds.includes(row.staffId) &&
				command.value == '1'
			) {
				this.$message.warning('当前员工人岗匹配进行中，请结束后审核');
				return;
			} else if (
				(row.subType == '3' || row.subType == '6') &&
				this.$store.state.app.range.performanceEmployeesIds &&
				this.$store.state.app.range.performanceEmployeesIds.includes(row.staffId) &&
				command.value == '1'
			) {
				this.$message.warning('当前员工绩效考核进行中，请结束后审核');
				return;
			} else {
				if (command.value == 1) {
					// 通过
					this.showType = 'pass';
				} else if (command.value == 2) {
					// 不通过
					this.showType = 'reject';
				}
				this.approvalParams.applyId = row.id;
				this.currentRow = row;
				this.approvalParams.approvalStatus = command.value;
				this.openDialog(command);
			}
		},
		submitApproval() {
			if (this.radioType == 1) {
				this.loading = true;
				this.submitting = true;
				const _obj = { ...this.approvalParams };

				_obj.fileUrl = JSON.stringify(this.uploadVal);
				operateApproval(_obj).then((res) => {
					this.submitting = false;
					this.loading = false;
					if (res._responseStatusCode === 0) {
						this.$message({
							type: 'success',
							message: '处理成功!'
						});
						this.dialogVisible = false;
						this.getPageData();
						this.getQueryTypeCount();
					}
				});
			} else {
				if (this.approvalParams.staffId == '') {
					this.$message.error('请选择转交人');
					return false;
				}
				const _obj = {
					applyId: this.approvalParams.applyId,
					type: this.radioType == 2 ? 1 : 2,
					staffId: this.approvalParams.staffId
				};

				this.loading = true;
				this.submitting = true;
				approvalChange(_obj).then((res) => {
					this.submitting = false;
					this.loading = false;
					if (res.code == 0) {
						this.approvalParams.staffId = '';
						this.$message({ type: 'success', message: '处理成功!' });
						this.dialogVisible = false;
						this.getPageData();
						this.getQueryTypeCount();
					}
				});
			}
		},
		showApplyApproval(mode, row) {
			// 入职特殊逻辑
			if ([8].includes(row?.subType)) {
				this.$router.push({
					path: '/staff/changeApply',
					query: { subType: row.subType, approvalId: row.approvalInfoId, staffId: row.staffId, applyId: row.id }
				});
				return;
			}
			// this.applyVisible = true;
			this.mode = mode;
			this.$router.push({
				path: '/staff/approval_apply',
				query: { mode, applyId: this.applyId }
			});
		},
		openDialog(action) {
			this.dialogAction = action;
			if (this.dialogAction === Application) {
				this.showApplyApproval(Mode.Add);
			} else {
				this.approvalParams.approvalDescription = '';
				this.radioType = '1';
				this.uploadVal = [];
				this.dialogVisible = true;
				this.$nextTick(() => {
					// eslint-disable-next-line no-unused-expressions
					this.$refs.upload && this.$refs.upload.clearFiles();
				});
			}
		},

		async getPageData() {
			this.pageLoading = true;
			this.loading = true;
			try {
				this.apiParamsToQuery();
				await this.getApprovalList();
				if (this.currentRow && this.currentRow.approvalStatusName === '待审批') {
					this.queryBadge();
				}
			} finally {
				this.loading = false;
				this.pageLoading = false;
			}
		},
		queryBadge() {
			getApprovalList({ pageSize: 5, page: 1, queryType: 2 }).then((res) => {
				if (res.totalNum > 0) {
					this.existApprovalPending = true;
				} else {
					this.existApprovalPending = false;
				}
			});
		},
		async queryrecruit(params) {
			return recruitAnewEntryApprovalList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					return res;
				}
				return null;
			});
		},
		async queryApprovalList(params) {
			return getApprovalList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					return res;
				}
				return null;
			});
		},
		async getApprovalList() {
			return getApprovalList(this.apiParams).then((res) => {
				this.tableList = res.list;
				this.totalPage = res.totalPage;
				if (this.$route.query.pageNo) {
					this.currentPage = Number(this.$route.query.pageNo);
				}
			});
		},
		getTable2List(event, page) {
			// eslint-disable-next-line no-unused-expressions
			page ? (this.table2Params.pageNo = page) : '';
			recruitAnewEntryApprovalList(this.table2Params).then((res) => {
				this.table2List = res.list;
				this.table2Params.totalNum = res.totalNum;
			});
		},
		getTable2Data() {
			this.table2Params.pageNo = 1;
			recruitAnewEntryApprovalList(this.table2Params).then((res) => {
				this.table2List = res.list;
				this.table2Params.totalNum = res.totalNum;
			});
		},
		table2Tab(row) {
			this.currentRow2 = row;
			this.form2.approvalId = row.approvalId;
			this.dialogVisible2 = true;
			this.$nextTick(() => {
				this.$refs.form2.clearValidate();
			});
		},
		table2TabClick(tab) {
			if (this.table2Params.queryType != tab.name) {
				this.table2Params.queryType = tab.name;
				this.getTable2List(1);
				this.getQueryTypeCount1();
			}
		},
		handleCurrentChange2(val) {
			this.getTable2List(val);
		},
		handleApproval() {
			this.$refs['form2'].validate((valid) => {
				if (valid) {
					const _obj = { ...this.form2 };

					if (_obj.status == 1 && _obj.reason == '') {
						_obj.reason = '同意';
					}
					this.handleApprovalLoading = true;
					recruitAnewEntryApproval(_obj).then((res) => {
						this.handleApprovalLoading = false;
						this.dialogVisible2 = false;
						if (res.code == 0) {
							this.$message.success('审批成功');
							this.getTable2List();
						}
					});
				}
			});
		},
		handleExceed(files, filelist) {
			if (files.length > 5 || filelist.length === 5 || files.length + filelist.length > 5) {
				this.$message({
					message: '上传文件数量不能超过五个',
					type: 'warning'
				});
			}
		},
		// 文件上传
		handleChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.uploadVal.push({
						name: res.fileName,
						size: '',
						url: res.httpUrl,
						bucketName: res.bucketName,
						key: res.key
					});
					this.contrachLoading = false;
				});
		},
		handleRemove(file) {
			const _list = [];

			for (const key in this.uploadVal) {
				if (this.uploadVal[key].name != file.name) {
					_list.push({
						name: this.uploadVal[key].name,
						detail: this.uploadVal[key].detail
					});
				}
			}
			this.uploadVal = _list;
		},
		_getStaff(val) {
			const _obj = {
				pageNo: 1,
				pageSize: 100,
				keyWork: val || ''
			};

			getStaffList({ request: _obj }).then((res) => {
				this.userArr = res.list;
			});
		},
		clickTableCell(e, column) {
			if (column?.property) {
				this.operate(e, Details);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.demo-form-inline {
	.el-form-item {
		margin: 10px 5px 15px 0;
	}
}
::v-deep .apprpvalsecondTab {
	.el-tabs__header {
		margin: -5px 0 10px;
	}
}
::v-deep .secondsTab {
	.el-tabs__header {
		margin: 5px 0 10px;
	}
}
</style>
