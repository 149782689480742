<template>
  <el-dialog :visible.sync="dialogVisible"
             :title="dialogAction">
    <el-form :label-width="(labelWidth+2)*14+'px'"
             class="approval-form">
      <el-form-item label="申请类型">
        <el-select v-if="mode === Mode.Add"
                   class="select-form-item"
                   style="width:100%;"
                   v-model="approvalType"
                   value-key="approvalId"
                   @change="changeApprovalProperties">
          <el-option v-for="(item, i) in approvalTypes"
                     :value="item"
                     :label="item.approvalName"
                     :key="i"></el-option>
        </el-select>
        <p v-else>
          {{approvalInfoName}}
        </p>
      </el-form-item>
      <el-divider></el-divider>
      <h3 style="font-size: 16px; margin: 30px 0;color: #484848; font-weight:bold;">申请详情</h3>
      <div v-loading="loading">
        <el-form-item v-for="(item ,i) in properties"
                      class="approval-form-item"
                      :label="item.approvalPropertyName"
                      :required="!!item.approvalPropertyRequired"
                      :key="i">
          <component :placeholder="item.approvalPropertyTip"
                     v-model="item.value"
                     v-if="'el-checkbox-group' === ccomponents[item.approvalPropertyType].name"
                     :is="ccomponents[item.approvalPropertyType].name">
            <el-checkbox :label="citem.id"
                         v-for="(citem,ci) in item.optionMap"
                         :key="ci">{{citem.value}}</el-checkbox>
          </component>
          <component :placeholder="item.approvalPropertyTip"
                     v-model="item.value"
                     :name="'radio'+item.approvalInfoId"
                     v-else-if="'el-radio-group' === ccomponents[item.approvalPropertyType].name"
                     :is="ccomponents[item.approvalPropertyType].name">
            <el-radio :label="citem.id"
                      v-for="(citem,ci) in item.optionMap"
                      :key="ci">{{citem.value}}</el-radio>
          </component>
          <component v-else-if="ccomponents[item.approvalPropertyType].name === 'p'"
                     :is="ccomponents[item.approvalPropertyType].name"
                     class="form-item-tip">
            {{item.approvalPropertyDefaultValue}}
          </component>
          <component v-else-if="ccomponents[item.approvalPropertyType].name === 'el-upload'"
                     multiple
                     :action="ccomponents[item.approvalPropertyType].props.action"
                     :limit="3"
                     name="imgFile"
                     :before-upload="handleBeforeUpload"
                     :on-success="(res, file,filelist)=>{handleUploaded(res,file, filelist, item)}"
                     :on-remove="(file, filelist)=>{handleRemoveFile(file, filelist, item)}"
                     :file-list="item.fileList"
                     :on-exceed="handleExceed"
                     :is="ccomponents[item.approvalPropertyType].name">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">{{item.approvalPropertyTip}}</div>
          </component>
          <div v-else-if="[Departments, Employees, PositionLevels].includes(ccomponents[item.approvalPropertyType].alias)">
            <component @click="openInnerDialog(ccomponents[item.approvalPropertyType].alias, item)"
                       type="text"
                       :is="ccomponents[item.approvalPropertyType].name">
              选择
            </component>
            <p>
              <el-tag closable
                      v-if="item.value&&item.value.id"
                      @close="removeTag(item)">{{item.value&&item.value.value}}</el-tag>
            </p>
          </div>
          <component v-else-if="ccomponents[item.approvalPropertyType].name==='el-select'"
                     :is="ccomponents[item.approvalPropertyType].name"
                     class="select-form-item"
                     :placeholder="item.approvalPropertyTip"
                     v-model="item.value">
            <el-option v-for="(option,i) in item.optionMap"
                       :label="option.value"
                       :value="option.id"
                       :key="i"></el-option>
          </component>
          <component :placeholder="item.approvalPropertyTip"
                     v-else
                     class="input-form-item"
                     :controls="false"
                     v-model="item.value"
                     v-bind="makeProps(item)"
                     :is="ccomponents[item.approvalPropertyType].name"></component>
          <!-- <component :placeholder="item.approvalPropertyTip"
                     v-else
                     class="input-form-item"
                     :controls="false"
                     v-model="item.value"
                     :rows="ccomponents[item.approvalPropertyType].props.rows"
                     :format="ccomponents[item.approvalPropertyType].props.format"
                     :value-format="ccomponents[item.approvalPropertyType].props.valueFormat"
                     :type="ccomponents[item.approvalPropertyType].props.type"
                     :maxlength=".maxlength"
                     v-bind="ccomponents[item.approvalPropertyType].props"
                     :is="ccomponents[item.approvalPropertyType].name"></component> -->
          <p></p>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog append-to-body
               top="10px"
               :title="innerDialogAction"
               :visible.sync="innerDialogVisible">
      <div style="height:80vh;">
        <table-position-levels v-if="innerDialogAction === PositionLevels"
                               @confirm="confirmSelect"
                               :show="innerDialogVisible"
                               @cancel="innerDialogVisible=false;"></table-position-levels>
        <tree-departments v-else-if="innerDialogAction === Departments"
                          @confirm="confirmSelect"
                          :show="innerDialogVisible"
                          @cancel="innerDialogVisible=false;"></tree-departments>
        <table-employees v-else-if="innerDialogAction === Employees"
                         @confirm="confirmSelect"
                         :show="innerDialogVisible"
                         :filter-params="employeesParams"
                         @cancel="innerDialogVisible=false;"></table-employees>
      </div>
    </el-dialog>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary"
                 :loading="uploadQueue.length>0"
                 @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import TablePositionLevels from './table-position-levels';
import TreeDepartments from './tree-departments';
import TableEmployees from './table-employees';
import { getApprovalProperties, applyApproval, getApplyApproval, updateApplyApproval, getApprovalType } from '../../../api/staffSystem';
import { Mode } from '../const';
import {FileUploadAction} from '../../../utils/const';
const Employees = '员工';
const PositionLevels = '职级体系';
const Departments = '组织';

let approvalValueMap = {};

export default {
  components: {
    TablePositionLevels, TreeDepartments, TableEmployees
  },
  props: {
    mode: {
      type: String,
      default: Mode.Add
    },
    action: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    }
  },
  watch: {
    visible: {
      handler (val) {
        this.submitting = false;
        this.dialogAction = this.mode;
        this.approvalTypeId = '';
        this.approvalType = null;
        this.$set(this, 'properties', []);
        this.loading = false;
        if (val) {
          this.dialogVisible = val;
          approvalValueMap = {};
          if (this.mode === Mode.Edit) {
            this.getApplyApproval(this.id);
          }
        }
      },
      immediate: true
    },
    dialogVisible (val) {
      if (!val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created () {
    this.getApprovalType();
  },
  data () {
    return {
      Mode,
      Employees,
      PositionLevels, Departments,
      labelWidth: 4,
      approvalInfoName: '',
      approvalTypeId: '',
      approvalTypes: [],
      approvalType: null,
      dialogAction: '',
      dialogVisible: false,
      loading: false,
      innerDialogVisible: false,
      innerDialogAction: '',
      properties: [],
      submitting: false,
      uploadQueue: [],
      activeDialogObj: null,
      ccomponents: {
        1: {
          name: 'el-input',
          props: {
            maxlength: 20
          }
        },
        2: {
          name: 'el-input',
          props: {
            rows: 4,
            maxlength: 200,
            type: 'textarea'
          }
        },
        3: {
          name: 'el-date-picker',
          props: {
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          }
        },
        4: {
          name: 'el-date-picker',
          multiple: true,
          props: {
            type: 'daterange',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          }
        },
        5: {
          name: 'el-radio-group',
          options: true,
          props: {

          }
        },
        6: {
          name: 'el-checkbox-group',
          options: true,
          multiple: true,
          props: {
            multiple: true
          }
        },
        7: {
          name: 'el-upload',
          multiple: true,
          files: true,
          props: {
            action: FileUploadAction
          }
        },
        8: {
          name: 'el-input-number',
          props: {
            min: 0,
            max: 10000000
          }
        },
        9: {
          name: 'el-date-picker',
          props: {
            type: 'date',
            format: 'yyyy-MM',
            valueFormat: 'yyyy-MM'
          }
        },
        10: {
          name: 'el-select',
          options: true
        },
        101: {
          name: 'el-button',
          alias: Employees,
          single: true,
          props: {

          }
        },
        102: {
          name: 'el-button',
          alias: PositionLevels,
          single: true,
          props: {

          }

        },
        103: {
          name: 'el-button',
          single: true,
          alias: Departments,
          props: {

          }
        }
      }
    };
  },
  methods: {
    makeProps (item) {
      if (item.props) {
        return {
          ...this.ccomponents[item.approvalPropertyType].props,
          ...item.props
        };
      }
        return this.ccomponents[item.approvalPropertyType].props;

    },
    async getApprovalType () {
      return getApprovalType().then((res) => {
        if (res._responseStatusCode === 0) {
          this.approvalTypes = res;
        }
      });
    },
    getApplyApproval (id) {
      approvalValueMap = {};
      getApplyApproval({ applyId: id }).then((res) => {
        if (res._responseStatusCode === 0) {
          this.approvalInfoName = res.approvalInfoName;
          this.approvalTypeId = res.approvalInfoId;
          res.approvalValueList.forEach((v) => {
            approvalValueMap[v.approvalPropertyName] = v;
          });
          this.changeApprovalProperties({ approvalId: this.approvalTypeId, subType: res.subType });
        }
      });
    },
    openInnerDialog (action, payload) {
      this.innerDialogAction = action;
      this.innerDialogVisible = true;
      this.activeDialogObj = payload;
    },
    handleExceed (files, filelist) {
      if (files.length > 3 || filelist.length === 3 || (files.length + filelist.length) > 3) {
        this.$message({
          message: '上传文件数量不能超过三个',
          type: 'warning'
        });
      }
    },
    handleUploaded (res, file, filelist, item) {
      this.uploadQueue.shift();
      if (res.code === 0) {
        this.$notify({
          type: 'success',
          message: '上传成功'
        });
        const resFileMeta = { 'name': res.data.fileName, 'size': file.size, 'url': res.data.httpUrl, 'bucketName': res.data.bucketName, 'key': res.data.key };

        item.value.push(resFileMeta);
      } else {
        this.$notify({
          type: 'error',
          message: '上传失败'
        });
        filelist.pop();
      }
    },
    handleBeforeUpload (file) {
      this.uploadQueue.push(1);
      return file;
    },
    handleRemoveFile (file, filelist, item) {
      if (file.status === 'uploading') {
        this.uploadQueue.pop();
      }
      let fileKey = '';

      if (file.response) {
        fileKey = file.response.data.key;
      } else {
        fileKey = file.key;
      }

      const index = item.value.findIndex((v) => v.key === fileKey);

      if (index !== -1) {
        item.value.splice(index, 1);
      }
    },
    confirmSelect (value) {
      if (value.length > 0) {
        if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
          if (this.$store.state.app.range.staffIds.includes(value[0].id)) {
            this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
            return;
          } if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
            this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
            return;
          }
      }
        this.activeDialogObj.value = value[0];
        if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
        if (this.$store.state.app.range.includes(this.activeDialogObj.value.id)) {
          this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
          return;
        } if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
            this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
            return;
          }
      }
        this.innerDialogVisible = false;
      }
    },
    removeTag (item) {
      this.$set(item, 'value', { id: '', value: '' });
    },
    async getApprovalProperties (id) {
      this.loading = true;
      this.properties = [];
      return getApprovalProperties({ approvalInfoId: id, approvalPropertyPosition: '1' }).then((res) => {
        if (res._responseStatusCode === 0) {
          this.properties = res;
          this.loading = false;
          this.properties.forEach((v) => {
            if (v.approvalPropertyName.length > this.labelWidth) {
              this.labelWidth = v.approvalPropertyName.length;
            }
            if (v.approvalPropertyName === '转正评价') {
              v.props = {};
              v.props.maxlength = 300;
            }
            this.setBlankValue(v);
            if (this.ccomponents[v.approvalPropertyType].options) {
              const optionArr = JSON.parse(v.approvalPropertyDefaultValue) || [];

              if (optionArr.length > 0 && v.approvalPropertyName === '奖惩类型') {
                const result = this.findApprovalType(id);

                if (result.approvalName === '奖惩') {
                  this.$set(v, 'value', optionArr[0].id);
                }
              }
              v.optionMap = {};
              optionArr.forEach((cv) => {
                v.optionMap[cv.id] = cv;
              });
            }
          });
          return true;
        }
      });
    },
    cancel () {
      this.dialogVisible = false;
      this.$emit('update:visible', false);
    },
    findApprovalType (id) {
      const result = this.approvalTypes.find((v) => v.approvalId === id);

      return result;
    },
    async changeApprovalProperties ({ approvalId, subType }) {
      this.approvalTypeId = approvalId;
      this.employeesParams = {
        notPositive: [1, 2].includes(subType) ? 1 : 0
      };
      const res = await this.getApprovalProperties(approvalId);

      if (this.mode === Mode.Edit && res) {
        this.properties.forEach((v) => {
          const value = approvalValueMap[v.approvalPropertyName];

          if (value) {
            if (this.ccomponents[v.approvalPropertyType].files) { // 附件
              this.$set(v, 'value', JSON.parse(value.approvalPropertyValue) || []);
              this.$set(v, 'fileList', JSON.parse(value.approvalPropertyValue) || []);
            } else if (this.ccomponents[v.approvalPropertyType].options && !this.ccomponents[v.approvalPropertyType].multiple) { // 单选
              this.$set(v, 'value', value.approvalPropertyValue);
            } else if (this.ccomponents[v.approvalPropertyType].options && Array.isArray(v.value)) { // 多选
              this.$set(v, 'value', value.approvalPropertyValue.split(','));
            } else if (this.ccomponents[v.approvalPropertyType].multiple && !this.ccomponents[v.approvalPropertyType].options && Array.isArray(v.value)) { // 数组
              this.$set(v, 'value', value.approvalPropertyValue.split(','));
            } else if (this.ccomponents[v.approvalPropertyType].single) {
              this.$set(v, 'value', { id: value.approvalPropertyValue, value: value.approvalPropertyValueName });
            } else {
              this.$set(v, 'value', value.approvalPropertyValue);
            }
          }
        });
      }
    },
    async submit () {
      if (!this.approvalTypeId) {
        this.$message({
          message: '请选择审批类型',
          type: 'warning'
        });
        return;
      }
      const approvalApplyValueList = [];

      let err = false;

      const errMsgList = [];

      this.properties.forEach((v) => {
        if (v.approvalPropertyRequired && (!v.value || v.value.length === 0)) {
          errMsgList.push(v.approvalPropertyName);
          err = true;
          return;
        }
        if (v.approvalPropertyKey == 'staffId' && (v.approvalInfoId == '6' || v.approvalInfoId == '3')) {
          if (this.$store.state.app.range.staffIds.includes(Number(v.value.id))) {
            errMsgList.push(false);
            err = true;
          } else if (this.$store.state.app.range.performanceEmployeesIds.includes(Number(v.value.id))) {
            errMsgList.push(false);
            err = true;
          } else if (!v.value.id) {
             if (this.$store.state.app.range.staffIds.includes(Number(this.$store.state.app.staffId))) {
              errMsgList.push(false);
              err = true;
             } else if (this.$store.state.app.range.performanceEmployeesIds.includes(Number(this.$store.state.app.staffId))) {
              errMsgList.push(false);
              err = true;
             }
          }
        }
        if (this.ccomponents[v.approvalPropertyType].options && !this.ccomponents[v.approvalPropertyType].multiple) {
          approvalApplyValueList.push({
            id: v.id,
            approvalPropertyValue: v.value,
            approvalPropertyValueName: v.value ? v.optionMap[v.value].value : ''
          });
        } else if (this.ccomponents[v.approvalPropertyType].options && Array.isArray(v.value)) {
          if (v.optionMap) {
            const value = v.value.map((cv) => ({
                id: cv,
                value: v.optionMap[cv]
              }));

            const approvalPropertyValueName = value.map((t) => {
              if (t.value) {
                return t.value.value;
              }
            });

            approvalApplyValueList.push({
              id: v.id,
              approvalPropertyValue: value.map((t) => t.id).join(','),
              approvalPropertyValueName: approvalPropertyValueName.join(',')
            });
          } else {
            approvalApplyValueList.push({
              id: v.id,
              approvalPropertyValue: v.value.map((t) => t.id).join(','),
              approvalPropertyValueName: v.value.map((t) => t.value).join(',')
            });
          }
        } else if (this.ccomponents[v.approvalPropertyType].multiple &&
          !this.ccomponents[v.approvalPropertyType].options &&
          !this.ccomponents[v.approvalPropertyType].files &&
          Array.isArray(v.value)) {
          approvalApplyValueList.push({
            id: v.id,
            approvalPropertyValue: v.value.join(',')
          });
        } else if (this.ccomponents[v.approvalPropertyType].files) {
          approvalApplyValueList.push({
            id: v.id,
            approvalPropertyValue: (v.value && v.value.length > 0) ? JSON.stringify(v.value) : '[]'
          });
        } else if (this.ccomponents[v.approvalPropertyType].single) {
          approvalApplyValueList.push({
            id: v.id,
            approvalPropertyValue: v.value ? v.value.id : '',
            approvalPropertyValueName: v.value ? v.value.value : ''
          });
        } else {
          approvalApplyValueList.push({
            id: v.id,
            approvalPropertyValue: v.value
          });
        }
      });
      if (!err) {
        this.submitting = true;
        if (this.mode === Mode.Add) {
          applyApproval({ approvalInfoId: this.approvalTypeId, approvalApplyValueList }).then((res) => {
            this.submitting = false;
            if (res._responseStatusCode === 0) {
              this.$message({
                type: 'success',
                message: '提交成功'
              });
              this.dialogVisible = false;
              this.$emit('success');
            }
          });
        } else if (this.mode === Mode.Edit) {
          updateApplyApproval({ applyId: this.id, approvalApplyValueList }).then((res) => {
            this.submitting = false;
            if (res._responseStatusCode === 0) {
              this.$message({
                type: 'success',
                message: '提交成功'
              });
              this.dialogVisible = false;
              this.$emit('success');
            }
          });
        }

      } else if (errMsgList.length > 3) {
          this.$message({
            type: 'warning',
            message: '请完整填写必填信息!'
          });
        } else if (!errMsgList[0] && errMsgList[0] == false) {
            this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
        } else {
          this.$message({
            type: 'warning',
            message: `${errMsgList.join(',')}为必填!`
          });
        }
    },
    setBlankValue (item) {
      if (this.ccomponents[item.approvalPropertyType].multiple) {
        this.$set(item, 'value', []);
      } else if (!this.ccomponents[item.approvalPropertyType].options) {
        this.$set(item, 'value', item.approvalPropertyDefaultValue || '');
      }
      if (this.ccomponents[item.approvalPropertyType].files) {
        this.$set(item, 'fileList', []);
      }
    }
  }
};
</script>
